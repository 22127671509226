
import {defineComponent,reactive,toRefs,onMounted } from "vue";
import formatDate from './../../util/formatDate';
import ImeiSeach from "@/components/search/ImeiSeach.vue";
import ServerChoose from '@/components/ServerChoose.vue';
import {getImeiList,deleteImei,clearRegist,isEditAuthByCode} from "@/api/index";
import { useStore } from 'vuex';
import { ElMessage,ElMessageBox } from 'element-plus';
interface deviceDataF{
    loading:boolean,
    iMeiData:Array<any>,  
    total:number,
    pageSize:number,
    pageSizes:Array<number>,
    currentPage:number,
    // startDate:string,
    // endDate:string,
    // status:string,
    // type:string,
    // keyWord:string,
    statusOption:Array<any>, 
    checkImeiArr:Array<any>,
    searchDataAny:any,//搜索条件
    editImeiFormData:any,
    editImeiFlag:boolean
}
// interface searchDataAny {
//     customerName:string,
//     dateValue: string,
//     statusVal:string,
//     typeVal:string
// }
export default defineComponent({
    name:'TableTem',
    components:{
        ImeiSeach,
        ServerChoose
    },
    setup(){
        const store = useStore();
        const deviceData:deviceDataF = reactive({
            loading:true,
            iMeiData:[],  
            total:2,
            pageSize:10,
            pageSizes:[10,30,50,100],
            currentPage:1,

            searchDataAny:{},//搜索条件
            editImeiFormData:{},
            editImeiFlag:false,
            // startDate:'',
            // endDate:'',
            // status:'',
            // keyWord:'',
            // type:'',
            statusOption:[
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '0',
                    label: '未绑定',
                },
                {
                    value: '1',
                    label: '已绑定(车联网)',
                },
                {
                    value: '2',
                    label: '已绑定(手表)',
                },
                {
                    value: '3',
                    label: '已绑定(宠物)',
                },
            ],
            //勾选的iMEI号
            checkImeiArr:[],
        });
        const data = toRefs(deviceData);
        const formatDateF = formatDate.formatDateTime;
        const searchData = (e:any)=>{
            //console.log('sdfsdf');

            deviceData.searchDataAny = e;
            let startDate,endDate;
            if(e && e.dateValue!=null){
                startDate = formatDate.handleTime(e.dateValue[0],"yyyy-MM-dd HH:mm:ss");
                endDate = formatDate.handleTime(e.dateValue[1],"yyyy-MM-dd");
                deviceData.searchDataAny.startDate = startDate;
                deviceData.searchDataAny.endDate = endDate+" 23:59:59";
            }
            
            // if(e.statusVal!=null){
            //     deviceData.status = e.statusVal;
            // }
            
            // if(e.customerName!=null){
            //     deviceData.keyWord = e.customerName;
            // }
            // if(e.typeVal!=null){
            //     deviceData.type = e.typeVal;
            // }
            

            deviceData.currentPage = 1;
            deviceData.loading = true;
            getImeiListFun();
        }

        const editerCustomer = ((e:any)=>{
            console.log('编辑');
            let obj = {imeis:''};
            Object.assign(obj, e);
            let imeis = "";
            if(deviceData.checkImeiArr!=null){
                deviceData.checkImeiArr.map((o:obj)=>{
                if(imeis.length>0){
                    imeis +=',';
                }
                imeis +=o.imei;
                });
                
                if(deviceData.checkImeiArr.length>0){
                    obj.imeis = imeis;
                }
            }
            
            deviceData.editImeiFormData = obj;
            deviceData.editImeiFlag = true;
        });
        
        const editImeiClosed = async(e:any)=>{
            
            deviceData.editImeiFlag = e;
            if(!e){
                // deviceData.editImeiFormData = {};
            }
        }

        const deleteCustomer = (async(e:any)=>{
            let params = {
                ip:store.state.chooseIp,
                imei:e.imei
            }
            await deleteImei(params).then((res:any)=>{
                if(res.status == 1){
                    ElMessage.success("删除成功");
                    deviceData.loading = true;
                    getImeiListFun();
                }else{
                    ElMessage.error("删除失败");
                }
            });
        });
        interface obj{
            [key:string]:any
        }
        const deleteImeiAll = async()=>{
            let imeiAll= rpx(deviceData.checkImeiArr);
            let params = {
                ip:store.state.chooseIp,
                imei:imeiAll
            }
            await deleteImei(params).then((res:any)=>{
                if(res.status == 1){
                    ElMessage.success("删除成功");
                    deviceData.loading = true;
                    getImeiListFun();
                }else{
                    ElMessage.error("删除失败");
                }
            });
        }

        const rpx = (data:any)=>{
            let imeiAll='';
            let reg=/,$/gi;
            data.map((e:obj)=>{
                imeiAll = imeiAll+e.imei+',';
            });
            imeiAll = imeiAll.replace(reg,"");
            return imeiAll;
        }
        const removeReg = async()=>{
            let imeiAll= rpx(deviceData.checkImeiArr);
            
            clearImeiRegist(imeiAll);
            // debugger
            
        }

        const clearImeiRegist = (imeis:string)=>{
            if(imeis.length <1){
                ElMessage.info('请选择要清除的数据')
                return;
            }
            let params = {
                imeis
            }

            ElMessageBox.confirm(
            '确定清除注册信息吗?',
            '警告',
            {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }
            ).then( async() => {
                await clearRegist(params).then((res:any)=>{
                    if(res.code == 0){
                        ElMessage.success("清除成功");
                        deviceData.loading = true;
                        getImeiListFun();
                    }else{
                        ElMessage.success("清除失败");
                    }
                })    
            })
        }

        const handleSizeChange = (val:any) => {
            //设置当前页显示几条数据
            deviceData.pageSize = val;
            getImeiListFun();
        }

        const handleCurrentChange = (val:any) => {
            //当前页码
            deviceData.currentPage = val;
            deviceData.loading = true;
            getImeiListFun();
        }

        const getImeiListFun = async()=>{        

            // let params ={
            //     // ip:store.state.chooseIp,
            //     page:deviceData.currentPage,
            //     length:deviceData.pageSize,
            //     startDate:deviceData.startDate,
            //     endDate:deviceData.endDate,
            //     status:deviceData.status,
            //     keyWord:deviceData.keyWord,
            //     type:deviceData.type,
            // }

            //把分页加入到查询条件
            deviceData.searchDataAny.page = deviceData.currentPage;
            deviceData.searchDataAny.length = deviceData.pageSize;
            
            await getImeiList(deviceData.searchDataAny).then((res:any)=>{
                
                // if(res.data!=null){
                //     deviceData.iMeiData = res.data.list;
                //     deviceData.total = res.data.total;
                //     ElMessage.success('加载成功!');
                // }                
                // deviceData.loading = false;
                if (res.code == 0) {
                    if(res.data!=null && res.data.list!=null){
                        deviceData.iMeiData = res.data.list
                        deviceData.total = res.data.total
                    }else{
                        deviceData.iMeiData = [];
                        deviceData.total = 0;
                        ElMessage.success('加载成功，数据为空！');
                    }
                    
                setTimeout(function(){
                    deviceData.loading = false
                },100);
                    // ElMessage.success('加载成功!')
                }
                else if(res.errMsg!=null){
                    ElMessage.error(res.errMsg);
                    deviceData.loading = false
                }else{
                    ElMessage.error("加载失败");
                    deviceData.loading = false
                }
            });
        }
        const addImeiSuccuss= ()=>{
            deviceData.currentPage = 1;
            deviceData.loading = true;
            getImeiListFun();
        }
        const selectionChange = (e:any)=>{
            deviceData.checkImeiArr = e;
            let imeis = "";
            e.map((o:obj)=>{
                if(imeis.length>0){
                    imeis +=',';
                }
                imeis +=o.imei;
            });
            
            let obj:any = {};
            if(e.length>0){
                Object.assign(obj, e[0]);
                obj.imeis = imeis;
            }
            
            deviceData.editImeiFormData = obj;
        }
        onMounted(async()=>{
            deviceData.loading = true;
            getImeiListFun();
        });
        return {
            ...data,
            formatDateF,
            searchData,
            editerCustomer,
            deleteCustomer,
            deleteImeiAll,
            editImeiClosed,
            removeReg,
            handleSizeChange,
            handleCurrentChange,
            addImeiSuccuss,
            selectionChange,
            clearImeiRegist,
            isEditAuthByCode
        }
    }
})
